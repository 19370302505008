import React, {useEffect, useState} from 'react';
import ImageUploading from 'react-images-uploading';
import { ReactComponent as ImageSVG } from "./fontawesome/svgs/solid/image.svg";
import { ReactComponent as CogSVG } from "./fontawesome/svgs/solid/cogs.svg";
import {isMobile} from "react-device-detect";

import axios from 'axios';
import './scss/style.scss';
import {Helmet} from "react-helmet";
import {URL} from "./constants";

const textAreaMaxChars = 165;
const nombreMaxChars = 25;

function makeid(length) {
    var result           = '';
    var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function App() {

    const [globos, setGlobos] = useState([]);
    const [selectedGlobo, setSelectedGlobo] = useState();
    const [zonas, setZonas] = useState([]);
    const [selectedZona, setSelectedZona] = useState();
    const [loading, setLoading] = useState(true);
    const [pictures, setPictures] = useState([]);
    const [token] = useState(makeid(32));
    const [texto, setTexto] = useState('');
    const [currentScreen, setCurrentScreen] = useState('datos'); /** datos | imagen */
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState(false);
    const [postalGenerada, setPostalGenerada] = useState(false);
    const [destinatario, setDestinatario] = useState('');
    const [nombre, setNombre] = useState('');


    useEffect(() => {

        axios.get(`${URL}index.php/postal/info`)
            .then(d => {
                const {data} = d;

                const _globos = Object.keys(data.globos).map(g => {
                    return {
                        'id': g,
                        'name': data.globos[g]
                    }
                }).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

                const _zonas = Object.keys(data.zonas).map(g => {
                    return {
                        'id': g,
                        'name': data.zonas[g]
                    }
                }).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

                setGlobos(_globos)
                setZonas(_zonas)
                setLoading(false)
            })

    }, [])

    const onImageChange = newPictures => {
        /**
         * el nombre está en plural por que damos por hecho que nos va a pedir que sea múltiple una vez esté funcionando
         */
        setPictures([...newPictures]);
    }

    const sendForm = () => {
        setPostalGenerada(false);
        const formData = new FormData();
        formData.append("image", pictures[0].file)
        formData.append("globo", selectedGlobo)
        formData.append("zona", selectedZona)
        formData.append("texto", texto)
        formData.append("token", token)
        formData.append("destinatario", destinatario)
        formData.append("nombre", nombre)


        axios.post(`${URL}index.php/postal/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            setPostalGenerada(URL + res.data);
        }).catch(e => {
            setErrors(['Ha habido un problema al subir la imagen']);
            setError(true);
        });
    }

    const goToScreenImagen = () => {
        if (currentScreen === 'imagen') return;
        const errors = [];
        if (!selectedZona)
            errors.push('Tienes que seleccionar una zona');

        if (!selectedGlobo)
            errors.push('Tienes que seleccionar el globo con el que volaste');

        if (!texto)
            errors.push('Tienes que introducir un texto');

        if (pictures.length === 0)
            errors.push('Tienes que subir una foto');

        if (destinatario.length === 0)
            errors.push('Tienes que seleccionar un destinatario');

        if (nombre.length === 0)
            errors.push('Tienes que agregar un nombre');

        if (errors.length > 0) {
            setError(true)
            setErrors(errors)
        }
        else {
            setCurrentScreen('imagen');
            sendForm()
        }
    }

    const goToScreenDatos = () => {
        if (!postalGenerada) return;
        setCurrentScreen('datos');
    }

    if (loading)
        return <p>Cargando</p>

    return (
        <div className="app">

            {error && <div className="app-error">

                <h2>Se han detectado los siguientes errores</h2>
                <ul>
                    {errors.map((e, i) => <li key={i}>{e}</li>)}
                </ul>

                <span onClick={() => setError(false)}>ok, entendido</span>
                <span className="close" onClick={() => setError(false)}>x</span>

            </div>}

            <header className="app-header has-gutter">
                <img src="https://static.siempreenlasnubes.com/uploads/2016/10/logo-horizontal-blanco.png" alt=""/>
            </header>

            <section className="app-body has-gutter">

                {currentScreen === 'datos' &&
                    <div className="row">
                        <div className="col form">

                            <div className="form-control">
                                <label htmlFor="globo">¿En qué globo volaste?</label>
                                <select name="globo" onChange={e => setSelectedGlobo(e.target.value)} value={selectedGlobo}>
                                    <option value="">Seleccione Globo</option>
                                    {globos.map(o => <option value={o.id} key={o.id}>{o.name}</option>)}
                                </select>
                            </div>

                            <div className="form-control">
                                <label htmlFor="globo">¿En qué zona volaste?</label>
                                <select name="globo" onChange={e => setSelectedZona(e.target.value)} value={selectedZona}>
                                    <option value="">Seleccione Zona</option>
                                    {zonas.map(o => <option value={o.id} key={o.id}>{o.name}</option>)}
                                </select>
                            </div>

                            <div className="form-control">
                                <label htmlFor="destinatario">Destinatario</label>
                                <select name="destinatario" onChange={e => setDestinatario(e.target.value)} value={destinatario}>
                                    <option value="">Seleccione un destinatario</option>
                                    <option value="Estimado">Estimado</option>
                                    <option value="Estimada">Estimada</option>
                                    <option value="Querido">Querido</option>
                                    <option value="Querida">Querida</option>
                                </select>
                            </div>

                            <div className="form-control">
                                <label htmlFor="nombre">Nombre <small>(quedan {nombreMaxChars - nombre.length} caracteres)</small>:</label>
                                <input type="text" name="nombre" value={nombre} onChange={e => e.target.value.length <= nombreMaxChars && setNombre(e.target.value)} />
                            </div>

                            <div className="form-control">
                                <label htmlFor="texto">Introduce tu dedicatoria <small>(quedan {textAreaMaxChars - texto.length} caracteres)</small>:</label>
                                <textarea name="texto" cols="30" onChange={e => e.target.value.length <= textAreaMaxChars && setTexto(e.target.value)} value={texto}/>
                            </div>
                        </div>

                        <div className="col images">
                            <ImageUploading
                                /*multiple*/
                                value={pictures}
                                onChange={onImageChange}
                                dataURLKey="data_url"
                            >
                                {({
                                      imageList,
                                      onImageUpload,
                                      onImageRemoveAll,
                                      onImageUpdate,
                                      onImageRemove,
                                      isDragging,
                                      dragProps,
                                  }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                        <button
                                            style={isDragging ? {color: 'red'} : undefined}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                            className="btn"
                                        >
                                            Seleccione imagen
                                        </button>
                                        &nbsp;
                                        {/*<button onClick={onImageRemoveAll}>Remove all images</button>*/}
                                        {pictures.map((image, index) => (

                                            <div key={index} className="image-item">
                                                <img src={image.data_url} alt="" width="100"/>
                                                <div className="image-item__btn-wrapper">
                                                    {/*<button onClick={() => onImageUpdate(index)}>Update</button>*/}
                                                    {/*<button onClick={() => onImageRemove(index)}>Quitar imagen</button>*/}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}

                            </ImageUploading>
                        </div>
                    </div>
                }

                {currentScreen === 'imagen' &&
                    <div>
                        {!postalGenerada && <p>Cargando</p>}

                        {postalGenerada && <div>
                            <Helmet>
                                <link rel="canonical" href={postalGenerada} />
                            </Helmet>
                            <img style={{maxWidth: '100%'}} alt="imagen para la postal" src={postalGenerada} />
                        </div>}

                        {postalGenerada && isMobile && <div>
                            <small style={{fontSize: 10.9}}>(Mantén pulsado sobre la imágen para descargar o para compartir)</small>
                        </div>}

                    </div>
                }

            </section>

            <footer className="app-footer has-gutter">
                <div className="row">
                    <div className={"col" + (currentScreen === 'datos' ? ' active' : '')}>
                        <div onClick={goToScreenDatos}>
                            <CogSVG height={20} />
                            <span>Introducir Datos</span>
                        </div>
                    </div>

                    <div className={"col" + (currentScreen === 'imagen' ? ' active' : '')}>
                        <div onClick={goToScreenImagen}>
                            <ImageSVG height={20}/>
                            <span>Ver Imagen</span>
                        </div>
                    </div>
                </div>

            </footer>

        </div>
    );
}

export default App;
